import cx from 'classnames'
import { Href } from 'components/navigation'


type LogoProps = {
  className?: string
}

const Logo: React.FC<LogoProps> = (props) => {
  const { className } = props

  return (
    <Href to="/" className={cx('flex items-center', className)}>
      <div className="relative">
        <img
          src="/images/gambiWhite.svg"
          alt="Gambi Logo"
          className="h-[42px]"
        />
        <img
          src="/images/gambi-star.svg"
          alt="Gambi Star"
          className="absolute -top-0 -right-2 h-3 w-3 animate-pulse"
        />
      </div>
    </Href>
  )
}

export default Logo

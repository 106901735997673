'use client'

import React, { useEffect, useState } from 'react'

import StarBackground from 'components/ui/StarBackground/StarBackground'
import { GlobalModalsRegistrar } from 'compositions/modals'

import { Content, Footer } from './components'


const PageLayout: React.CFC = (props) => {
  let { children } = props

  const [isAllowed, setIsAllowed] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // useEffect(() => {
  //   fetch('/api/geo')
  //     .then(res => {
  //       if (!res.ok) {
  //         throw new Error(`HTTP error! status: ${res.status}`);
  //       }
  //       const contentType = res.headers.get("content-type");
  //       if (contentType && contentType.indexOf("application/json") !== -1) {
  //         return res.json();
  //       }
  //     })
  //     .then(data => {
  //       if (data.error) {
  //         setIsAllowed(false);
  //         setError(data.error);
  //       }
  //     })
  //     .catch(err => {
  //       console.error('Error checking geolocation:', err);
  //       setError(err.message);
  //       setIsAllowed(false);
  //     });
  // }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }



  return (
    <>
      <StarBackground />
      <Content>
        {/* {isAllowed ? ( */}
          <>
            <div className="flex-1">{children}</div>
            <Footer />
            <GlobalModalsRegistrar />
          </>
        {/* ) : <div>Access denied based on your location.</div>} */}

      </Content>
      <div id="modals" />
    </>
  )
}

export default PageLayout
